import PropTypes from 'prop-types';

export default function DeviceMotionPermission({shakePermissionButtonLabel, shakePermissionMessage, onRequestDeviceMotionPermissions}) {
  return (
    <div className="notice warning">
      <p style={{margin: '0'}}>
        {shakePermissionMessage ? shakePermissionMessage :
          'Pour utiliser l\'effet de shake it get it veuillez autoriser l\'utilisation du device motion.'}
        <a href="javascript:void(0);"
          onClick={onRequestDeviceMotionPermissions}>{shakePermissionButtonLabel ? shakePermissionButtonLabel : 'Autoriser'}</a>
      </p>
    </div>
  );
}

DeviceMotionPermission.propTypes = {
  shakePermissionButtonLabel: PropTypes.string,
  shakePermissionMessage: PropTypes.string,
  onRequestDeviceMotionPermissions: PropTypes.func
};
