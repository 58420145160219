import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    Authorization: `apikey ${process.env.REACT_APP_API_KEY}`,
    "content-type": "application/ld+json",
  },
});

export default axiosInstance;

export const getWallConfig = (sessionKey) => {
  return asyncFetchUrl(
    process.env.REACT_APP_SOCKET_IO_HOST + "/api/wall-config/" + sessionKey
  );
};

export const getDevice = async (deviceUid) => {
  return await asyncFetchUrl("/devices/" + deviceUid);
};

export const getConfigByIriWithImages = async (iri) => {
  const config = await getByIri(iri);
  const imagesProperties = [
    "mobileBackground",
    "mobileLogo",
    "mobileHeaderBackground",
    "mobileFooterBackground",
    "mobileRetrieveIcon",
  ];
  const urlSearchParams = new URLSearchParams();
  for (const imgProperty of imagesProperties) {
    if (typeof config[imgProperty] !== "undefined") {
      urlSearchParams.append("id[]", config[imgProperty]);
    }
  }
  const configImages = await getCollection(
    "/images?" + urlSearchParams.toString()
  );
  for (const configImage of configImages) {
    for (const imgProperty of imagesProperties) {
      if (config[imgProperty] === configImage["@id"]) {
        config[imgProperty] = configImage;
        break;
      }
    }
  }
  return config;
};

export const getByIri = async (iri) => {
  return asyncFetchUrl(iri);
};

export const getCollection = async (url) => {
  const response = await asyncFetchUrl(url);
  return response["hydra:member"] || [];
};

export const asyncFetchUrl = async (url) => {
  try {
    return (await axiosInstance.get(url)).data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const createActivateAction = async (action) => {
  return axiosInstance({
    method: "POST",
    url: "/activate_actions",
    data: JSON.stringify(action),
  });
};

export const createRetrieveAction = async (action) => {
  return axiosInstance({
    method: "POST",
    url: "/retrieve_actions",
    data: JSON.stringify(action),
  });
};

export const createTestDriveRequestAction = async (action) => {
  return axiosInstance({
    method: "POST",
    url: "/test_drive_request_actions",
    data: JSON.stringify(action),
  });
};

export const createReceiveViaEmailAction = async (action) => {
  return axiosInstance({
    method: "POST",
    url: "/receive_via_email_actions",
    data: JSON.stringify(action),
  });
};

export const createShareAction = async (action) => {
  return axiosInstance({
    method: "POST",
    url: "/share_actions",
    data: JSON.stringify(action),
  });
};

export const createDownloadAction = async (action) => {
  return axiosInstance({
    method: "POST",
    url: "/download_actions",
    data: JSON.stringify(action),
  });
};

export const sendVehicleBrochureDocumentViaEmail = async (
  vehicleId,
  personId
) => {
  return (
    await axiosInstance({
      method: "POST",
      url: `/vehicles/${vehicleId}/send-brochure-document-via-email?person=${personId}`,
      data: JSON.stringify({}),
    })
  ).data;
};

export const sendPostViaEmailEmail = async (
  postIri,
  participantId,
  isDocument = false
) => {
  let url = `${postIri}/send-via-email?person=${participantId}${isDocument ? "&document=true" : ""
    }`;
  const response = await axiosInstance.post(url, {});
  return response.status === 201 || response.status === 200;
};

export const sendProductAssetsViaEmail = async (
  productIri,
  email
) => {
  const { id } = productIri;
  let url = `${process.env.REACT_APP_API_HOST}/posts/${id}/send-via-email`;
  const response = await axios.post(
    url,
    {
      email
    },
    {
      headers: {
        Authorization: `apikey ${process.env.REACT_APP_API_KEY}`,
      },
    }
  );
  return response.status
};

export const updateSessionActions = async (
  {
    startTime,
    visitSession,
    post,
    type,
    project,
    device
  }
) => {
  let url = `${process.env.REACT_APP_API_HOST}/actions`;
  const response = await axios.post(
    url,
    {
      startTime,
      visitSession,
      post,
      type,
      project,
      device
    },
    {
      headers: {
        Authorization: `apikey ${process.env.REACT_APP_API_KEY}`,
      },
    }
  );
  return response.status
}
