import "./App.css";
import { Shake } from "./shake.js/shake";
import {
  createActivateAction,
  createRetrieveAction,
  getByIri,
  getConfigByIriWithImages,
  getWallConfig,
  sendPostViaEmailEmail,
  sendProductAssetsViaEmail,
  updateSessionActions,
} from "./services/mainService";
import {
  requestATestDrive,
  trackDownloadAction,
  trackReceiveViaEmailAction,
  trackShareAction,
} from "./services/actionHandler";
import WallWebSocket from "./services/wallWebSocket";
import { createNewStyleElement, isMobile, nl2br } from "./util";
import InvalidSession from "./components/invalidSession";
import DeviceMotionPermission from "./components/deviceMotionPermission";
import Notice from "./components/notice";
import { Fragment, useEffect, useRef, useState } from "react";
import Header from "./components/header";
import Navigation from "./components/navigation";
import ShakingPhone from "./components/shakingPhone";
import ItemsList from "./components/itemsList";
import ItemDetail from "./components/itemDetail";
import UsedCarsFilter from "./components/usedCarsFilter";
import Swal from "sweetalert2";


const SESSION_KEY = parseInt(window.location.pathname.slice(1));

export default function App() {
  const socketRef = useRef(null);
  const [requestedDeviceMotionPermission, setRequestedDeviceMotionPermission] =
    useState(false);
  const [validSession, setValidSession] = useState(null);
  const [participant, setParticipant] = useState(null);
  const [device, setDevice] = useState(null);
  const [config, setConfig] = useState(null);
  const [items, setItems] = useState([]);
  const [userSessionExpired, setUserSessionExpired] = useState(false);
  const [
    testDriveRequestSentSuccessfully,
    setTestDriveRequestSentSuccessfully,
  ] = useState(false);
  const [testDriveRequestSentError, setTestDriveRequestSentError] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [close, setClose] = useState(false);
  const [shakeIcon, setShakeIcon] = useState(true);
  const [mailSent, setMailSent] = useState(false);
  const [mailNotSent, setMailNotSent] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [shakeOccured, setShakeOccured] = useState(false)
  const itemsListRef = useRef([]);

  useEffect(() => {
    const fetchData = async () => {
      if (SESSION_KEY && !isNaN(SESSION_KEY)) {
        try {
          const data = await getWallConfig(SESSION_KEY);
          const device = data.device;
          const participant = data.participant;
          if (!device.contentWallConfig) {
            setValidSession(false);
            return;
          }
          const config = await getConfigByIriWithImages(
            device.contentWallConfig
          );
          if (
            typeof config.mobileCssStyle !== "undefined" &&
            config.mobileCssStyle !== null
          ) {
            createNewStyleElement(config.mobileCssStyle, document);
          }
          setDevice(device);
          setParticipant(participant);
          setConfig(config);
          setValidSession(true);
          if (participant) {
            const activateAction = {
              person: participant["@id"],
              project: device.project,
              device: device["@id"],
            };
            if (
              typeof device.application !== "undefined" &&
              device.application !== null
            ) {
              activateAction.application = device.application["@id"];
            }
            createActivateAction(activateAction);
          }
        } catch (e) {
          console.log(e);
          setValidSession(false);
        }
        if (!isMobile.iOS()) {
          setupShakeEvent();
        }
      } else {
        setValidSession(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!device || socketRef.current) {
      return;
    }
    socketRef.current = new WallWebSocket(
      SESSION_KEY,
      async (itemIri) => {
        console.log('retrieve iri', itemIri);
        const item = await getByIri(itemIri);
        console.log('retrieve item', item);
        try {
          itemsListRef.current = [item, ...itemsListRef.current];
          setItems(itemsListRef.current);
          const retrieveAction = {
            person: participant['@id'],
            project: device.project,
            device: device['@id'],
            object: item['@type'] === 'Post' ? item['@id'] : null,
            ad: item['@type'] === 'Vehicle' ? item['@id'] : null,
          };
          if (device.application) {
            retrieveAction.application = device.application['@id'];
          }
          createRetrieveAction(retrieveAction);
        } catch (e) {
          console.log(e);
        }
      },
      () => {
        console.log('disconnected');
        setClose(false);
        setUserSessionExpired(true);
      }
    );
  }, [participant, device, items]);

  const handleTrackReceiveViaEmailAction = (item) => {
    trackReceiveViaEmailAction(item, participant, device);
  };

  const handleTrackShareAction = (post, platform) => {
    trackShareAction(post, platform, participant, device);
  };

  const handleTrackDownloadAction = (item) => {
    trackDownloadAction(item, participant, device);
  };

  const handleRequestATestDrive = async (item) => {
    const response = await requestATestDrive(item, participant, device);
    if (response.status === 201) {
      setTestDriveRequestSentSuccessfully(true);
      setTimeout(() => {
        setTestDriveRequestSentSuccessfully(false);
      }, 5000);
    } else {
      setTestDriveRequestSentError(true);
      setTimeout(() => {
        setTestDriveRequestSentError(false);
      }, 5000);
    }
  };

  useEffect(() => {
    if (shakeOccured) {
      console.log(items[0])
      if (typeof (items[0]) !== 'undefined' && sessionId) {
        updateSessionActions({
          startTime: new Date(),
          visitSession: sessionId,
          product: items[0]['@id'],
          type: "retrieve",
          project: device.project,
          device: device['@id']
        })
      }
    }

  }, [shakeOccured, items, sessionId])

  const shakeEventDidOccur = () => {
    socketRef.current.emit("social/shake", { key: SESSION_KEY });
    setShakeOccured(true)
  };

  const navigateRight = () => {
    socketRef.current.emit("social/swipe", {
      key: SESSION_KEY,
      direction: "right",
    });
  };

  const navigateLeft = () => {
    socketRef.current.emit("social/swipe", {
      key: SESSION_KEY,
      direction: "left",
    });
  };

  const setupShakeEvent = () => {
    let event = new Shake({ threshold: 10, timeout: 1000 });
    event.start();
    window.addEventListener("shake", shakeEventDidOccur, false);
  };

  const requestDeviceMotionPermissions = () => {
    setRequestedDeviceMotionPermission(true);
    // feature detect
    if (typeof DeviceMotionEvent.requestPermission === "function") {
      DeviceMotionEvent.requestPermission()
        .then((permissionState) => {
          if (permissionState === "granted") {
            setupShakeEvent();
          }
        })
        .catch(console.error);
    } else {
      // handle regular non iOS 13+ devices
    }
  };

  const onHandleSelectItem = (socialPost) => {
    setSelectedItem(socialPost);
    setClose(true);
    setShakeIcon(true);
  };

  const handleOnClose = () => {
    setSelectedItem(null);
    setShakeIcon(true);
    setClose(false);
  };

  /*componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.config.close === false) {
      this.setState({shakeIcon: true, close: true});
    }
  }*/

  const sendEmail = async (post, isDocument = false) => {
    if (await sendPostViaEmailEmail(post["@id"], participant.id, isDocument)) {
      setMailSent(true);
      setClose(false);
      setTimeout(() => {
        setMailSent(false);
        setClose(true);
      }, 2000);
      handleTrackReceiveViaEmailAction(post);
    } else {
      setMailNotSent(true);
      setClose(false);
      setTimeout(() => {
        setMailNotSent(false);
        setClose(true);
      }, 2000);
    }
  };

  /*
  const sendEmail = async (product) => {
    console.log({ product });
    Swal.fire({
      padding: '5em 0em',
      title: 'Enter Your email',
      input: 'text',
      inputValue: participant.email,
      inputAttributes: {
        style: 'padding: 1em; font-size: 20px; height: 3em;',
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Get Email',
      confirmButtonColor: '#111',
      showLoaderOnConfirm: true,
      preConfirm: (email) => {
        return sendProductAssetsViaEmail(product, email);
      },
    }).then((result) => {
      console.log(result);
      if (result.value === 204 || result.value === 201) {
        Swal.fire({
          title: 'Email is successfuly sent ',
          padding: '3em 0em',
        });
      } else {
        Swal.fire({
          title: 'something went wrong',
          padding: '3em 0em',
        });
      }
    });
  };
*/
  const handleOnFilter = (filters) => {
    socketRef.current.emit("filterContent", { ...filters, key: SESSION_KEY });
  };


  if (validSession === false) {
    return <InvalidSession />;
  }
  if (!config) {
    return <p>Loading..</p>;
  }


  let notice = null;
  if (!userSessionExpired) {
    if (testDriveRequestSentSuccessfully) {
      notice = (
        <Notice
          classes={"warning success"}
          message={
            config.testDriveRequestSuccessMessage ||
            "Votre demande teste drive est envoyée avec succès."
          }
        />
      );
      notice = (
        <Notice
          classes={'warning success'}
          message={
            config.testDriveRequestSuccessMessage ||
            'Votre demande teste drive est envoyée avec succès.'
          }
        />
      );
    } else if (testDriveRequestSentError) {
      notice = (
        <Notice
          message={
            config.testDriveRequestErrorMessage ||
            "Un erreur est survenu lors d'envoi de votre demande de teste drive."
          }
        />
      );
      notice = (
        <Notice
          message={
            config.testDriveRequestErrorMessage ||
            'Un erreur est survenu lors d\'envoi de votre demande de teste drive.'
          }
        />
      );
    }
  } else {
    notice = (
      <Notice
        message={config.expiredSessionMessage || "Votre session est expiré."}
      />
    );
  }
  if (userSessionExpired) {
    notice = (
      <Notice
        message={config.expiredSessionMessage || "Votre session est expiré."}
      />
    );
  }

  const {
    mobileBackgroundColor,
    mobileColor,
    mobileBackGround,
    mobileFooterBackground,
    mobileHeaderBackground,
    mobileRetrieveContentMessage,
    mobileRetrieveIcon,
    testDriveRequestLabel,
    receiveViaEmailEnabled,
    mobileRetrieveSectionTitle,
    mobileShareSectionTitle,
    testDriveRequestEnabled,
    socialMediaShareActionTitle,
    mobileNavigationMessage,
  } = config;
  return (
    <div className={`App ${items.length ? "has-items" : ""}`}>
      {userSessionExpired === false &&
        isMobile.iOS() &&
        requestedDeviceMotionPermission === false && (
          <DeviceMotionPermission
            shakePermissionButtonLabel={config.shakePermissionMessage}
            shakePermissionMessage={config.shakePermissionButtonLabel}
            onRequestDeviceMotionPermissions={requestDeviceMotionPermissions}
          />
        )}

      {notice && notice}

      {validSession && (
        <div
          className="mainStyle"
          style={{
            backgroundImage: "url(" + mobileBackGround + ")",
            backgroundColor: mobileBackgroundColor,
            color: mobileColor,
          }}
        >
          {selectedItem && close === false && (
            <div>
              {mailSent && (
                <Notice classes={"warning success"} message={"mail envoyé"} />
              )}
              {mailNotSent && (
                <Notice
                  classes={"alert"}
                  message={
                    "Erreur d&apos;envoi d&apos;email, essayer ulterieurement"
                  }
                />
              )}
            </div>
          )}
          <div
            className="mainStyle"
            style={{
              backgroundImage: 'url(' + mobileBackGround + ')',
              backgroundColor: mobileBackgroundColor,
              color: mobileColor,
            }}
          >
            {selectedItem && close === false && (
              <div>
                {mailSent && (
                  <Notice classes={'warning success'} message={'mail envoyé'} />
                )}
                {mailNotSent && (
                  <Notice
                    classes={'alert'}
                    message={
                      'Erreur d&apos;envoi d&apos;email, essayer ulterieurement'
                    }
                  />
                )}
              </div>
            )}
            <main id="container">
              <Header
                onClose={handleOnClose}
                sessionExpired={userSessionExpired}
                mobileHeaderBackground={mobileHeaderBackground}
                enableCLose={selectedItem !== null}
              />

              {!selectedItem && !userSessionExpired && (
                <Fragment>
                  {/* <UsedCarsFilter onFilter={handleOnFilter} /> */}
                  <Navigation
                    onNavigateRight={navigateRight}
                    onNavigateLeft={navigateLeft}
                    mobileNavigationMessage={mobileNavigationMessage}
                  />
                </Fragment>
              )}

              {shakeIcon && (
                <ShakingPhone
                  mobileRetrieveIcon={mobileRetrieveIcon}
                  getCurrentPost={shakeEventDidOccur}
                />
              )}

              {mobileRetrieveContentMessage !== null &&
                (!items || items.length === 0) && (
                  <p
                    className="shake-description"
                    dangerouslySetInnerHTML={{
                      __html: nl2br(mobileRetrieveContentMessage),
                    }}
                  />
                )}

              {items && items.length > 0 && (
                <div id="social-posts" className={!selectedItem ? "list" : ""}>
                  {!selectedItem && (
                    <ItemsList onSelectItem={onHandleSelectItem} items={items} />
                  )}
                  {selectedItem && (
                    <ItemDetail
                      device={device}
                      sessionId={sessionId}
                      item={selectedItem}
                      onRequestATestDrive={handleRequestATestDrive}
                      testDriveRequestLabel={testDriveRequestLabel}
                      testDriveRequestEnabled={testDriveRequestEnabled}
                      receiveViaEmailEnabled={receiveViaEmailEnabled || false}
                      mobileRetrieveSectionTitle={mobileRetrieveSectionTitle}
                      mobileShareSectionTitle={mobileShareSectionTitle}
                      socialMediaShareActionTitle={socialMediaShareActionTitle}
                      onSendEmail={sendEmail}
                      onTrackShareAction={handleTrackShareAction}
                      onTrackDownloadAction={handleTrackDownloadAction}
                    />
                  )}
                </div>
              )}
            </main>
            {mobileFooterBackground && (
              <div
                className={"footer"}
                style={{ backgroundImage: `url(${mobileFooterBackground})` }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}


