import PropTypes from 'prop-types';
import {Carousel} from 'react-bootstrap';

export default function Gallery({items}) {
  return (
    <Carousel interval={null}>
      {items.map(item => (
        <Carousel.Item key={item['@type']+item.id}>
          {item['@type'] === 'http://schema.org/ImageObject' ?
            <img src={item.contentUrl} alt={item.name} className="img-fluid"/> :
            <div className="embed-responsive">
              <video src={item.contentUrl} controls className="embed-responsive-item"/>
            </div>
          }
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

Gallery.propTypes = {
  items: PropTypes.array.isRequired
};
