export function nl2br(str, is_xhtml) {
  if (typeof str === "undefined" || str === null) {
    return "";
  }
  var breakTag =
    is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
  return (str + "").replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    "$1" + breakTag + "$2"
  );
}

export const isMobile = {
  Android: () => /Android/i.test(navigator.userAgent),
  iOS: () => /iPhone|iPad|iPod/i.test(navigator.userAgent),
};

export function getListImg(socialPost) {
  console.log(socialPost);
  if (socialPost.heroImage) return socialPost.heroImage;
  else {
    const image =
      socialPost.image || (socialPost.photos && socialPost.photos[0]) || null;
    const video =
      socialPost.video || (socialPost.videos && socialPost.videos[0]) || null;
    if (image) {
      if (Array.isArray(image.thumbnails) && image.thumbnails.length > 0) {
        try {
          return image.thumbnails.find(
            (t) => t.sizeLabel === "medium" || t.sizeLabel === "small"
          ).contentUrl;
        } catch (e) {
          return image.contentUrl;
        }
      } else {
        return image.contentUrl;
      }
    }
    if (
      video &&
      Array.isArray(video.thumbnails) &&
      video.thumbnails.length > 0
    ) {
      return video.thumbnails.find(
        (t) => t.sizeLabel === "medium" || t.sizeLabel === "small"
      ).contentUrl;
    }
  }
}

export function createNewStyleElement(css, document) {
  const head = document.head || document.getElementsByTagName("head")[0];
  const style = document.createElement("style");
  head.appendChild(style);
  style.appendChild(document.createTextNode(css));
}
