import icon from '../../assets/img/send-via-email-icon.png';
import PropTypes from 'prop-types';
import { updateSessionActions } from '../../services/mainService';

export default function ReceiveViaEmailButton({ disabled, label, onSendEmail, item, device,
  sessionId }) {

  console.log({
    startTime: new Date(),
    visitSession: sessionId,
    product: item['@id'],
    type: "receive_via_email",
    project: device.project,
    device: device['@id']
  })
  return (
    <button style={disabled ? { opacity: '0.5' } : {}} className="btn-send-me-via-email"
      disabled={disabled}
      onClick={() => {
        onSendEmail()
        updateSessionActions({
          startTime: new Date(),
          visitSession: sessionId,
          post: item['@id'],
          type: "receive_via_email",
          project: device.project,
          device: device['@id']
        })

      }}
    >
      <img src={icon} alt="Receive via email" />
      {label ? (<span dangerouslySetInnerHTML={{ __html: label }} />) :
        (<span>Recevoir <br />par email</span>)}
    </button>
  );
}

ReceiveViaEmailButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onSendEmail: PropTypes.func,
  item: PropTypes.object
};
