import playIcon from "../assets/img/play.png";
import { getListImg } from "../util";
import PropTypes from "prop-types";

export default function ListItem({ item, onSelectItem }) {
  const listImg = getListImg(item);
  // const video = item.video || (item.videos && item.videos[0]) || null;
  return (
    <div className="social-post" onClick={() => onSelectItem(item)}>
      <div className="preview">
        <div className={"list-img-container"}>
          {/* <div className={`${video ? "play-container" : ""}`}> */}
          {listImg && (
              <img
                src={listImg}
                style={{ maxWidth: "100%" }}
                alt={item.name || item.title}
              />
            )}
            {/* <img
              src={playIcon}
              className="img-play"
              onClick={() => onSelectItem(item)}
              alt="play button"
            /> */}
          {/* </div> */}
        </div>
        <p>{item.name || item.title}</p>
      </div>
    </div>
  );
}

ListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onSelectItem: PropTypes.func,
};
