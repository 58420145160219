import PropTypes from "prop-types";
import ListItem from "./listItem";

export default function ItemsList({ items, onSelectItem }) {
  return (
    <div className={"posts-list"}>
      {items.map((item) => (
        <ListItem
          key={item.id || item["@id"]}
          item={item}
          onSelectItem={onSelectItem}
        />
      ))}
    </div>
  );
}

ItemsList.propTypes = {
  onSelectItem: PropTypes.func,
  items: PropTypes.array,
};
