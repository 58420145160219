import whatsappIcon from '../../assets/img/whatsapp-icon.png';
import PropTypes from 'prop-types';

export default function WhatsappButton({url, onShare}) {
  return (
    <a className={'btn-share'}
      href={`whatsapp://send?text=${encodeURIComponent(url)}`}
      onClick={() => onShare('whatsapp')}
      target="_blank" rel="noopener noreferrer">
      <img src={whatsappIcon} alt="Share via whatsapp"/></a>
  );
}

WhatsappButton.propTypes = {
  onShare: PropTypes.func,
  url: PropTypes.string
};
