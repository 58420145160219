import icon from '../../assets/img/whatsapp-black.png';
import PropTypes from 'prop-types';

export default function ContactViaWhatsappButton({link}) {
  return (
    <a className="btn-contact-whatsapp" href={link}>
      <img src={icon} alt="Contact via whatsapp"/>
    </a>
  );
}

ContactViaWhatsappButton.propTypes = {
  link: PropTypes.string.isRequired
};
