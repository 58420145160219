import openSocket from 'socket.io-client';

export default class WallWebSocket {
  socket = null;

  constructor(sessionKey, onRetrieve, onDisconnect) {
    this.socket = openSocket(process.env.REACT_APP_SOCKET_IO_HOST);
    this.socket.on('connect', () => {
      this.socket.emit('identify', {
        id: this.socket.id,
        key: sessionKey
      });
      console.log({id: this.socket.id, key: sessionKey});
    });

    this.socket.on('social/post', async itemIri => {
      onRetrieve(itemIri);
    });

    this.socket.on('disconnect', onDisconnect);
  }

  emit = (topic, payload) => {
    this.socket.emit(topic, payload);
  };

  disconnect = () => {
    this.socket.disconnect();
  };
}
