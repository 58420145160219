import closeIcon from '../assets/img/wall-mobile-close-icon.png';
import PropTypes from 'prop-types';

export default function Header({sessionExpired, enableCLose, mobileHeaderBackground, onClose}) {
  return (<header style={sessionExpired ? {
    backgroundImage: `url(${mobileHeaderBackground?.contentUrl})`,
    marginTop: '50px'
  } : {backgroundImage: `url(${mobileHeaderBackground?.contentUrl})`}}
  className="header">
    {enableCLose && close &&
    <button id="close-post" className="btn-fermer" onClick={onClose}>
      <img src={closeIcon} alt="close"/>
    </button>}
  </header>);
}

Header.propTypes = {
  onClose: PropTypes.func,
  sessionExpired: PropTypes.bool,
  mobileHeaderBackground: PropTypes.object,
  enableCLose: PropTypes.bool
};
