import icon from '../../assets/img/download-black.png';
import PropTypes from 'prop-types';
import { updateSessionActions } from '../../services/mainService';

export default function DownloadButton({ url, label, onDownload, item, device,
  sessionId }) {


  console.log({
    startTime: new Date(),
    visitSession: sessionId,
    product: item['@id'],
    type: "download",
    project: device.project,
    device: device['@id']
  })

  return (
    <a className="btn-download" href={url} target="_blank" rel="noopener noreferrer" >
      <div className="row align-items-center align-content-center" onClick={() => {
        updateSessionActions({
          startTime: new Date(),
          visitSession: sessionId,
          post: item['@id'],
          type: "download",
          project: device.project,
          device: device['@id']
        })
      }}>
        {icon && <div className="col-auto">
          <img src={icon} alt="Download" />
        </div>}
        {label ? (
          <div className={'col btn-label'} dangerouslySetInnerHTML={{ __html: label }} />
        ) : (
          <span className={'col btn-label'}>Téléchargez</span>
        )}
      </div>
    </a>
  );
}

DownloadButton.propTypes = {
  label: PropTypes.string,
  onDownload: PropTypes.func,
  url: PropTypes.string.isRequired
};
