import PropTypes from 'prop-types';
import shakePhone from '../assets/img/shake-phone.png';

export default function ShakingPhone({mobileRetrieveIcon, getCurrentPost}) {
  return (
    <div id="shaking-phone" className="shake-get-it">
      <div id="mainInteraction">
        <div id="shakeVisual" className="activeVisual">
          <div className="red bar">
            <img src={mobileRetrieveIcon && mobileRetrieveIcon.contentUrl || shakePhone} id="phoneIcon" className={'antiSlanted shaky'} width="120"
              alt="shake mobile" onClick={getCurrentPost}/>
            <span className="visualText barOverylay shaky">
              <span id="textShake"><font style={{verticalAlign: 'inherit'}}><font
                style={{verticalAlign: 'inherit'}}>shake </font></font></span>
              <span id="textIt"><font style={{verticalAlign: 'inherit'}}><font
                style={{verticalAlign: 'inherit', color: '#1d3d99'}}>it </font></font></span>
              <span id="textGetIt"><font style={{verticalAlign: 'inherit'}}><font
                style={{verticalAlign: 'inherit'}}>get it</font></font></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

ShakingPhone.propTypes = {
  getCurrentPost: PropTypes.func,
  mobileRetrieveIcon: PropTypes.string
};
