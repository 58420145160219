import ShareSection from "./shareSection";
import RetrieveSection from "./retrieveSection";
import PropTypes from "prop-types";
import Gallery from "./gallery";

export default function ItemDetail({
  device,
  sessionId,
  item,
  testDriveRequestLabel,
  testDriveRequestEnabled,
  receiveViaEmailEnabled,
  mobileRetrieveSectionTitle,
  mobileShareSectionTitle,
  socialMediaShareActionTitle,
  onSendEmail,
  onTrackShareAction,
  onRequestATestDrive,
  onTrackDownloadAction,
}) {
  console.log("this is from itemsDetail",item)
  const image = item.image || null;
  const video = item.video;

  let itemImageContentUrl = null;
  let itemVideoContentUrl = null;
  let itemDownloadUrl = null;
  let itemMainMediaUrl;
  let videoThumbnails = [];
  if (video) {
    videoThumbnails = video.thumbnails;
    if (videoThumbnails instanceof Array && videoThumbnails.length > 0) {
      if (typeof videoThumbnails !== "undefined") {
        itemImageContentUrl = videoThumbnails.find(
          (videoThumbnail) => videoThumbnail.sizeLabel === "medium"
        ).contentUrl;
      }
    }
    itemVideoContentUrl = video.contentUrl;
    itemDownloadUrl = process.env.REACT_APP_API_HOST + video['@id'] + "/download";
    itemMainMediaUrl = itemVideoContentUrl;
  } else if (item.downloadableDocument) {
    itemImageContentUrl = image.contentUrl;
    itemDownloadUrl = process.env.REACT_APP_API_HOST + item.downloadableDocument['@id'] + "/download";
    itemMainMediaUrl = item.downloadableDocument.contentUrl;
  } else if (item.image) {
    itemImageContentUrl = item.image.contentUrl;
    itemMainMediaUrl = itemImageContentUrl;
    itemDownloadUrl = process.env.REACT_APP_API_HOST + item.image['@id'] + "/download";
  }
  // } else {
  //   itemImageContentUrl = image ? image.contentUrl : null;
  //   itemDownloadUrl = image ? image.contentUrl + "?download" : null;
  //   itemMainMediaUrl = itemImageContentUrl;
  // }
  // let galleryItems = [];
  // if (item["@type"] === "Vehicle") {
  //   if (item.videos) {
  //     galleryItems = galleryItems.concat(item.videos);
  //   }
  //   if (item.photos) {
  //     galleryItems = galleryItems.concat(item.photos);
  //   }
  // }
  // console.log("item:", item);
  // console.log("galleryItems:", galleryItems);
  // if (item["@type"] === "Vehicle" && item.specificationsDocument) {
  //   itemMainMediaUrl = item.specificationsDocument.contentUrl;
  //   itemDownloadUrl = itemMainMediaUrl + "?download";
  // }

  return (
    <div className="social-post">
      <div className="detail">
        {item["@type"] === "Vehicle" ? (
          <Gallery items={galleryItems} />
        ) : (
          <>
            {!video && (
              <img
                src={itemImageContentUrl ? itemImageContentUrl : item.heroImage}
                style={{ maxWidth: "100%" }}
                alt={item.title ? item.title : item.name}
              />
            )}
            {video && (
              <video
                style={{ width: "100%", height: "100%" }}
                poster={itemImageContentUrl}
                controls
              >
                <source src={video.contentUrl} />
              </video>
            )}
          </>
        )}
        {item.title ? (<p>{item.title}</p>) : (<p>{item.name}</p>)}


        <RetrieveSection
          item={item}
          device={device}
          sessionId={sessionId}
          title={mobileRetrieveSectionTitle}
          contactLink={item.contactLink}
          receiveViaEmailButtonLabel={
            item.downloadableDocumentSendViaEmailButtonLabel
          }
          downloadButtonLabel={item.downloadableDocumentDownloadButtonLabel}
          receiveViaEmailEnabled={receiveViaEmailEnabled}
          downloadUrl={itemDownloadUrl}
          testDriveRequestEnabled={testDriveRequestEnabled}
          testDriveRequestLabel={testDriveRequestLabel}
          onSendEmail={onSendEmail}
          onTestDriveRequest={() => onRequestATestDrive(item)}
          onTrackDownloadAction={onTrackDownloadAction}
        />
      </div>

      <ShareSection
        item={item}
        quote={socialMediaShareActionTitle}
        sectionTitle={mobileShareSectionTitle}
        receiveViaEmailEnabled={receiveViaEmailEnabled || false}
        mainMediaUrl={itemMainMediaUrl}
        onShare={onTrackShareAction}
      />
    </div>
  );
}

ItemDetail.propTypes = {
  mobileRetrieveSectionTitle: PropTypes.string.isRequired,
  mobileShareSectionTitle: PropTypes.string.isRequired,
  receiveViaEmailEnabled: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  socialMediaShareActionTitle: PropTypes.string.isRequired,
  testDriveRequestEnabled: PropTypes.bool.isRequired,
  testDriveRequestLabel: PropTypes.string.isRequired,
  onSendEmail: PropTypes.func.isRequired,
  onTrackShareAction: PropTypes.func.isRequired,
  onRequestATestDrive: PropTypes.func.isRequired,
  onTrackDownloadAction: PropTypes.func.isRequired,
};
