import PropTypes from 'prop-types';

export default function Notice({message, classes = 'warning'}) {
  return (
    <div className={`notice ${classes}`}>
      <p style={{margin: '0'}}>{message}</p>
    </div>
  );
}

Notice.propTypes = {
  classes: PropTypes.string,
  message: PropTypes.string
};
