import { Fragment, useState } from "react";
import { isMobile, nl2br } from "../util";
import ContactViaWhatsappButton from "./buttons/contactViaWhatsappButton";
import ReceiveViaEmailButton from "./buttons/receiveViaEmailButton";
import DownloadButton from "./buttons/downloadButton";
import PropTypes from "prop-types";
// import RequestTestDriveButton from "./buttons/requestTestDriveButton";

export default function RetrieveSection({
  device,
  sessionId,
  item,
  title,
  receiveViaEmailEnabled,
  receiveViaEmailButtonLabel,
  testDriveRequestEnabled,
  testDriveRequestLabel,
  onTestDriveRequest,
  onSendEmail,
  downloadUrl,
  downloadButtonLabel,
  onTrackDownloadAction,
  contactLink,
}) {
  const [receiveViaEmailButtonDisabled, setReceiveViaEmailButtonDisabled] =
    useState(false);
  const [testDriveRequestDisabled, setTestDriveRequestDisabled] =
    useState(false);

  // const handleSendMail = () => {
  //   // setReceiveViaEmailButtonDisabled(true);
  //   onSendEmail();
  // };
  const handleRequestTestDrive = () => {
    setTestDriveRequestDisabled(true);
    onTestDriveRequest();
  };

  return (
    <Fragment>
      {title && (
        <h2
          className={"section-title"}
          dangerouslySetInnerHTML={{ __html: nl2br(title) }}
        />
      )}

      {contactLink && <ContactViaWhatsappButton link={contactLink} />}

      {isMobile.iOS() ? (
        <div>
          {receiveViaEmailEnabled !== false && (
            <div style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}>
              <ReceiveViaEmailButton
                device={device}
                sessionId={sessionId}
                item={item}
                disabled={receiveViaEmailButtonDisabled}
                label="Recevoir par email"
                onSendEmail={() => onSendEmail(item)}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <DownloadButton
            device={device}
            sessionId={sessionId}
            item={item}
            label="Télécharger"
            url={downloadUrl}
            onDownload={onTrackDownloadAction}
          />
        </div>
      )}

      {/*{testDriveRequestEnabled === true && testDriveRequestEnabled === true && (
        <RequestTestDriveButton
          label={testDriveRequestLabel}
          disabled={testDriveRequestDisabled}
          onRequestATestDrive={handleRequestTestDrive}
        />
      )}*/}
    </Fragment>
  );
}

RetrieveSection.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  receiveViaEmailEnabled: PropTypes.bool.isRequired,
  receiveViaEmailButtonLabel: PropTypes.string,
  testDriveRequestEnabled: PropTypes.bool.isRequired,
  testDriveRequestLabel: PropTypes.string,
  onTestDriveRequest: PropTypes.func,
  onSendEmail: PropTypes.func.isRequired,
  downloadButtonLabel: PropTypes.string,
  downloadUrl: PropTypes.string.isRequired,
  onTrackDownloadAction: PropTypes.func,
  contactLink: PropTypes.string,
};
