import leftArrowIcon from '../assets/img/arrow-left.png';
import {nl2br} from '../util';
import rightArrowIcon from '../assets/img/arrow-right.png';
import PropTypes from 'prop-types';

export default function Navigation({onNavigateLeft, mobileNavigationMessage, onNavigateRight}) {
  return (
    <div id={'nav-elements'} className="row no-gutters">
      <div className="col-auto">
        <div className="arrow-left">
          <img src={leftArrowIcon} alt="left arrow"
            onClick={onNavigateLeft}/>
        </div>
      </div>
      <div className="col">
        <div className="navigation-message"
          dangerouslySetInnerHTML={{__html: mobileNavigationMessage ? nl2br(mobileNavigationMessage) : '<p>Utilisez les flèches pour découvrir le contenu mis a votre disposition sur l’écran en face</p>'}}/>
      </div>
      <div className="col-auto">
        <div className="arrow-right">
          <img src={rightArrowIcon} alt="right arrow"
            onClick={onNavigateRight}/>
        </div>
      </div>
    </div>
  );
}

Navigation.propTypes = {
  mobileNavigationMessage: PropTypes.string,
  onNavigateLeft: PropTypes.func,
  onNavigateRight: PropTypes.func,
};
