import twitterIcon from '../../assets/img/twitter-icon.png';
import PropTypes from 'prop-types';

export default function TwitterButton({url, onShare}) {
  return (
    <a href={`https://twitter.com/share?url=${encodeURIComponent(url)}`}
      className="btn-share"
      onClick={() => onShare('twitter')}
      target="_blank" rel="noopener noreferrer">
      <img src={twitterIcon} alt="Share on twitter"/></a>
  );
}

TwitterButton.propTypes = {
  onShare: PropTypes.func,
  url: PropTypes.string
};
