import {nl2br} from '../util';
import PropTypes from 'prop-types';
import WhatsappButton from './buttons/whatsappButton';
import TwitterButton from './buttons/twitterButton';
import FacebookButton from './buttons/facebookButton';
import MailButton from './buttons/mailButton';

export default function ShareSection({sectionTitle, item, mainMediaUrl, quote, onShare}) {
  return (
    <div className="share-block">
      <div className="share-block-container">
        {sectionTitle &&
        <h2 className="section-title" dangerouslySetInnerHTML={{__html: nl2br(sectionTitle)}}/>}
        <div className="row">
          <div className="col">
            <WhatsappButton url={mainMediaUrl} onShare={(platform) => onShare(item, platform)}/>
          </div>
          <div className="col">
            <TwitterButton onShare={(platform) => onShare(item, platform)} url={mainMediaUrl}/>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <FacebookButton url={mainMediaUrl} quote={quote}
              onShare={(platform) => onShare(item, platform)}/>
          </div>
          <div className="col">
            <MailButton onShare={(platform) => onShare(item, platform)} url={mainMediaUrl}/>
          </div>
        </div>
      </div>
    </div>
  );
}

ShareSection.propTypes = {
  item: PropTypes.object.isRequired,
  mainMediaUrl: PropTypes.string.isRequired,
  onShare: PropTypes.func.isRequired,
  quote: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string
};
