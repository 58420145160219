import icon from '../assets/img/bt-icon.png';

const style = {
  width: '100%',
  height: '100vh',
  backgroundColor: 'white',
  textAlign: 'center',
  color: 'black'
};

export default function InvalidSession() {
  return (
    <div style={style}>
      {icon &&
      <img src={icon} alt="Invalid session" style={{marginTop: '40px'}}/>}
      <p style={{padding: '15px', margin: '25px auto', lineHeight: '23px'}}>
        Ce lien est invalide, veuillez scanner votre carte sur le gallery
        wall.
      </p>
    </div>
  );
}
