import mailIcon from '../../assets/img/mail-icon.png';
import PropTypes from 'prop-types';

export default function MailButton({url, onShare}) {
  return (
    <a className="btn-share"
      href={`mailto:?body=${encodeURIComponent(url)}`}
      onClick={() => onShare('email')}
      target="_blank" rel="noopener noreferrer">
      <img src={mailIcon} alt="Share via email"/></a>
  );
}

MailButton.propTypes = {
  onShare: PropTypes.func,
  url: PropTypes.string
};
