import {
  createDownloadAction,
  createReceiveViaEmailAction,
  createShareAction,
  createTestDriveRequestAction,
} from "./mainService";

export const trackReceiveViaEmailAction = (item, participant, device) => {
  const receiveViaEmailAction = {
    person: participant["@id"],
    project: device.project,
    device: device["@id"],
    post: item["@id"],
  };
  if (
    typeof item.downloadableDocument !== "undefined" &&
    item.downloadableDocument !== null
  ) {
    receiveViaEmailAction.document = item.downloadableDocument["@id"];
  } else if (typeof item.video !== "undefined" && item.video !== null) {
    receiveViaEmailAction.video = item.video["@id"];
  } else if (typeof item.image !== "undefined" && item.image !== null) {
    receiveViaEmailAction.image = item.image["@id"];
  }
  if (
    typeof device.application !== "undefined" &&
    device.application !== null
  ) {
    receiveViaEmailAction.application = device.application["@id"];
  }
  createReceiveViaEmailAction(receiveViaEmailAction);
};

export const trackShareAction = (post, platform, participant, device) => {
  const shareAction = {
    person: participant["@id"],
    project: device.project,
    device: device["@id"],
    object: post["@id"],
    platform: platform,
  };
  if (
    typeof device.application !== "undefined" &&
    device.application !== null
  ) {
    shareAction.application = device.application["@id"];
  }
  createShareAction(shareAction);
};

export const trackDownloadAction = (item, participant, device) => {
  const downloadAction = {
    person: participant["@id"],
    project: device.project,
    device: device["@id"],
    post: item["@id"],
  };
  if (
    typeof item.downloadableDocument !== "undefined" &&
    item.downloadableDocument !== null
  ) {
    downloadAction.document = item.downloadableDocument["@id"];
  } else if (typeof item.video !== "undefined" && item.video !== null) {
    downloadAction.video = item.video["@id"];
  } else if (typeof item.image !== "undefined" && item.image !== null) {
    downloadAction.image = item.image["@id"];
  }
  if (
    typeof device.application !== "undefined" &&
    device.application !== null
  ) {
    downloadAction.application = device.application["@id"];
  }
  createDownloadAction(downloadAction);
};

export const requestATestDrive = async (item, participant, device) => {
  const testDriveRequestAction = {
    person: participant["@id"],
    project: device.project,
    device: device["@id"],
    post: item["@id"],
  };
  if (item.product !== null) {
    testDriveRequestAction.product = item.product;
  }
  if (
    typeof device.application !== "undefined" &&
    device.application !== null
  ) {
    testDriveRequestAction.application = device.application["@id"];
  }
  return createTestDriveRequestAction(testDriveRequestAction);
};
