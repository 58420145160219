import facebookIcon from '../../assets/img/facebook-icon.png';
import PropTypes from 'prop-types';

export default function FacebookButton({url, quote, onShare}) {
  return (
    <a className="btn-share"
      href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${quote}`}
      onClick={() => onShare('facebook')}
      target="_blank" rel="noopener noreferrer">
      <img src={facebookIcon} alt="Share on facebook"/></a>
  );
}

FacebookButton.propTypes = {
  url: PropTypes.string,
  quote: PropTypes.string,
  onShare: PropTypes.func,
};
